import * as _ from 'lodash';

const generateLimitedRange = rangeValues => {
  const toRange = (accumulator, currentValue, index) => {
    if (rangeValues[index - 1] === undefined) {
      return [];
    }
    return [
      ...accumulator,
      {
        lowerLimit: rangeValues[index - 1],
        upperLimit: currentValue,
      },
    ];
  };
  return rangeValues.reduce(toRange, []);
};
export const getLaunchAngleRanges = () => {
  const launchAngleRanges = [-100, -10, 0, 10, 20, 30, 40, 100];
  return generateLimitedRange(launchAngleRanges);
};
export const getPitchVelocityRanges = () => {
  const pitchVeloRanges = [60, 70, 75, 80, 85, 90, 120];
  return generateLimitedRange(pitchVeloRanges);
};
export const getHorizontalAngleRanges = () => {
  const ranges = [-46, -15, 15, 45];
  return generateLimitedRange(ranges);
};
export const getPOIRanges = () => {
  const ranges = [-18.0, -12.0, -6.0, 0.0, 6.0, 12.0, 18.0, 30.0];
  return generateLimitedRange(ranges);
};
export const getAtBatsInLaunchVeloRange = (atBats, range) =>
  atBats.filter(({ launchAngle }) =>
    _.inRange(launchAngle, range.lowerLimit, range.upperLimit),
  );
export const getAtBatsInPitchVeloRange = (atBats, range) =>
  atBats.filter(({ pitchVelocity }) =>
    _.inRange(pitchVelocity, range.lowerLimit, range.upperLimit),
  );
export const getAtBatsInHorizontalAngleRange = (atBats, range) =>
  atBats.filter(({ horizontalAngle }) =>
    _.inRange(horizontalAngle, range.lowerLimit, range.upperLimit),
  );
export const getAtBatsInPOIRange = (atBats, range) =>
  atBats.filter(
    ({ poiX, poiY, poiZ }) =>
      !(poiX === 0.0 && poiY === 0.0 && poiZ === 0.0) &&
      _.inRange(poiZ - 17.0, range.lowerLimit, range.upperLimit),
  );
