import React from 'react';
import { useSelector } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import './index.css';
import { getPlayerName } from '../../reducer/session';
import TopLineAndTables from './components/TopLineAndTables';
import Charts from './components/Charts';
import Header from './components/Header';

const Report = () => {
  const playerName = useSelector(getPlayerName);

  return (
    <>
      {!!playerName && (
        <div className="report">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              className="data-container"
            >
              <Header playerName={playerName} />
              <TopLineAndTables />
              <Charts />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Report;
