import * as _ from 'lodash';

export const getAtBatsOver50PitchVelocity = atBats =>
  atBats.filter(atBat => parseFloat(atBat.pitchVelocity) >= 50);

export const getAtBatsOver50ExitVelocityAndPitchVelocity = atBats =>
  atBats.filter(
    atBat =>
      parseFloat(atBat.exitVelocity) >= 50 &&
      parseFloat(atBat.pitchVelocity) >= 50,
  );
export const getBallsInPlay = state =>
  getAtBatsOver50ExitVelocityAndPitchVelocity(
    state.session.atBats,
  ).filter(atBat => _.inRange(atBat.horizontalAngle, -45.0, 45.0));
