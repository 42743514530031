import React from 'react';
import ExitVelocity from './components/ExitVelocity';
import LaunchAngleTopLine from './components/LaunchAngleTopLine';
import ContactRateSluggingAndOPS from './components/ContactRateSluggingAndOPS';
import TotalPitchesAndBallsInPlay from './components/TotalPitchesAndBallsInPlay';
import LaunchAngleMetrics from './components/LaunchAngleMetrics';
import PitchVeloData from './components/PitchVeloData';
import StrikeZoneData from './components/StrikeZoneData';
import style from '../../../style';

const TopLineAndTables = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-evenly' }}
      className="group"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '30px',
          flex: '1',
        }}
        className="average-max-column column"
      >
        <ExitVelocity />
        <LaunchAngleTopLine />
        <ContactRateSluggingAndOPS />
      </div>
      <div
        style={{
          display: 'flex',
          padding: '30px',
          flexDirection: 'column',
          flex: '1',
        }}
        className="launch-angle-column column"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '-15px 20px 20px 20px',
          }}
          className="column"
        >
          <TotalPitchesAndBallsInPlay />
        </div>
        <LaunchAngleMetrics />
      </div>
      <div
        style={{
          display: 'flex',
          padding: '30px',
          flexDirection: 'column',
          flex: '1',
        }}
        className="pitch-velocity-column column"
      >
        <PitchVeloData />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              ...style,
              marginBottom: '10px',
              fontSize: '20px',
            }}
          >
            Strike Zone
          </p>
          <StrikeZoneData />
        </div>
      </div>
    </div>
  );
};

export default TopLineAndTables;
