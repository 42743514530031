import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import Report from './components/report';
import Select from './components/select/RequestReport';
import NameSelectModal from './components/modals/NameSelectModal';

const getAwaitingName = state => state.session.isAwaitingName;

const App = () => {
  const isAwaitingName = useSelector(getAwaitingName);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Gamers' Hittrax Assessment</h1>
      </header>
      <div id="main">
        <Select />
        <Report />
      </div>
      <footer>
        <NameSelectModal show={isAwaitingName} />
      </footer>
    </div>
  );
};

export default App;
