import React from 'react';
import { useSelector } from 'react-redux';
import {
  getTotalBallsInPlay,
  getTotalPitches,
} from '../../../../../reducer/session';
import style from '../../../../style';

const styles = {
  dataText: {
    margin: 'auto',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
};

const TotalPitchesAndBallsInPlay = () => {
  const totalPitches = useSelector(getTotalPitches);
  const ballsInPlay = useSelector(getTotalBallsInPlay);

  return (
    <>
      <div style={{ ...style, ...styles.dataContainer }}>
        <h3 style={{ ...style, ...styles.dataText }}>
          {`Balls in play: ${ballsInPlay}`}
        </h3>
        <h3 style={{ ...style, ...styles.dataText }}>
          {`Total Strikes: ${totalPitches}`}
        </h3>
      </div>
    </>
  );
};

export default TotalPitchesAndBallsInPlay;
