import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAverageExitVelocity,
  getStrikeZoneData,
} from '../../../../../reducer/session';

export const getCellColorForZone = (totalAvg, zoneAvg) => {
  if (!zoneAvg) return '';
  switch (true) {
    case totalAvg - 3 > zoneAvg:
      return 'red-cell';
    case totalAvg + 3 < zoneAvg:
      return 'green-cell';
    default:
      return 'yellow-cell';
  }
};

const StrikeZoneData = () => {
  const data = useSelector(getStrikeZoneData);
  const totalAvgEv = useSelector(getAverageExitVelocity);

  return (
    <div className="strike-zone-grid-container">
      {data.map(({ position, avgEv, avgLa }) => (
        <div
          key={position}
          className={`strike-zone-grid-item ${getCellColorForZone(
            totalAvgEv,
            avgEv,
          )}`}
        >
          <p style={{ margin: '8px' }}>{avgEv ? avgEv.toFixed(0) : '-'}</p>
          <p style={{ margin: '8px', direction: 'ltr' }}>
            {avgLa ? avgLa.toFixed(0) : '-'}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StrikeZoneData;
