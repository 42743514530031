import React from 'react';
import { useSelector } from 'react-redux';
import baseballDiamond from '../../../../../assets/baseball-field.svg';
import '../../../index.css';
import { getSprayChartData } from '../../../../../reducer/session';
import style from '../../../../style';

const renderDataSet = (data, position) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      padding: '5px',
    }}
  >
    <p style={style}>
      {position === 'left' ? 'L' : position === 'center' ? 'C' : 'R'}
    </p>
    <p style={style}>{parseFloat(data[position].percent).toFixed(1)}%</p>
    <p style={style}>{parseFloat(data[position].avgEv).toFixed(1)}</p>
    <p style={style}>{parseFloat(data[position].avgLa).toFixed(1)}</p>
  </div>
);

const SprayChartData = () => {
  const data = useSelector(getSprayChartData);

  return (
    <>
      <p style={{ ...style, fontSize: '20px' }}>Spray Chart</p>
      <div style={{ display: 'flex', margin: 'auto' }}>
        {renderDataSet(data, 'left')}
        {renderDataSet(data, 'center')}
        {renderDataSet(data, 'right')}
        <div
          style={{
            flexDirection: 'column',
            flex: '1',
            alignItems: 'flex-end',
            padding: '5px',
          }}
          className="spray-labels"
        >
          <p style={style}>
            <br />
            <br />
            <br />
            avgEV
          </p>
          <p style={style}>avgLA</p>
        </div>
      </div>
      <div>
        <img
          src={baseballDiamond}
          alt="Spray chart"
          style={{ width: '200px' }}
          className="ball-field"
        />
      </div>
    </>
  );
};

export default SprayChartData;
