import React from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../style';
import { getPitchVelocityData } from '../../../../../reducer/session';

const PitchVeloData = ({ usePdfStyles }) => {
  const styles = base => ({
    base,
    row: { display: 'flex', justifyContent: 'space-between' },
    title: { ...base, fontSize: '20px', margin: '0px auto 0px auto' },
    firstCell: { ...base, minWidth: '30px' },
  });

  const { base, row, firstCell, title } = styles(
    !usePdfStyles ? style : { color: 'black', fontSize: '12px' },
  );
  const data = useSelector(getPitchVelocityData);

  return (
    <div style={base}>
      <div style={title}>Pitch Velocity</div>
      <div style={row}>
        <p style={firstCell}>Range</p>
        <p style={base}>avgEv</p>
        <p style={base}>avgLa</p>
      </div>
      {data.map(({ range: { lowerLimit, upperLimit }, avgEv, avgLa }) => {
        return (
          <div style={row}>
            <p style={firstCell}>
              {/* eslint-disable prettier/prettier */}
              {   lowerLimit === 60  ? `< ${upperLimit}`
                : upperLimit !== 120 ? `${lowerLimit}-${upperLimit}`
                :                      `> ${lowerLimit}`}
              {/* eslint-enable prettier/prettier */}
            </p>
            <p style={base}>{avgEv ? avgEv.toFixed(1) : '-'}</p>
            <p style={base}>{avgLa ? avgLa.toFixed(1) : '-'}</p>
          </div>
        );
      })}
    </div>
  );
};

export default PitchVeloData;
