import React from 'react';
import { useSelector } from 'react-redux';
import ExportPdf from '../report/reportPDF/ExportPdf';
import ImportFromFileBody from './ImportFromFileBody';
import 'react-day-picker/lib/style.css';
import './index.css';
import { getPlayerName } from '../../reducer/session';
import { Col, Row } from 'antd';

const RequestReport = () => {
  const playerName = useSelector(getPlayerName);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginBottom: '40px' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <ImportFromFileBody />
          </Col>
          <Col span={12}>{playerName && <ExportPdf />}</Col>
        </Row>
      </div>
    </div>
  );
};

export default RequestReport;
