/* eslint-disable no-use-before-define */
import axios from 'axios';
import {
  getAverageExitVelocity,
  getAverageHardHitBallLaunchAngle,
  getAverageLaunchAngle,
  getContactRate,
  getLaunchAngleData,
  getMaxExitVelocity,
  getOnbasePlusSlugging,
  getPitchVelocityData,
  getPlayerName,
  getPOIData,
  getSluggingPercentage,
  getSprayChartData,
  getStandardDeviationOfExitVelocity,
  getStrikeZoneData,
  getTotalBallsInPlay,
  getTotalPitches,
  getVerticalAngleData,
} from '../session';

export const SET_REPORT = 'SET_REPORT';

export const SET_REPORT_HISTORY = 'SET_REPORT_HISTORY';

export const generateReport = () => (dispatch, getState) => {
  const state = getState();
  const playerName = getPlayerName(state);
  const totalPitches = getTotalPitches(state);
  const ballsInPlay = getTotalBallsInPlay(state);
  const avgExitVelocity = getAverageExitVelocity(state);
  const maxExitVelocity = getMaxExitVelocity(state);
  const standardDeviationOfExitVelocity = getStandardDeviationOfExitVelocity(
    state,
  );
  const averageLaunchAngle = getAverageLaunchAngle(state);
  const averageHardHitBallLaunchAngle = getAverageHardHitBallLaunchAngle(state);
  const sluggingPercentage = getSluggingPercentage(state);
  const onbasePlusSlugging = getOnbasePlusSlugging(state);
  const contactRate = getContactRate(state);
  const launchAngleData = getLaunchAngleData(state);
  const pitchVelocityData = getPitchVelocityData(state);
  const sprayChartDate = getSprayChartData(state);
  const pointOfImpactData = getPOIData(state);
  const strikeZoneData = getStrikeZoneData(state);
  const verticalAngleData = getVerticalAngleData(state);
  return dispatch(
    setReport({
      playerName,
      totalPitches,
      ballsInPlay,
      avgExitVelocity,
      maxExitVelocity,
      standardDeviationOfExitVelocity,
      averageLaunchAngle,
      averageHardHitBallLaunchAngle,
      sluggingPercentage,
      onbasePlusSlugging,
      contactRate,
      launchAngleData,
      pitchVelocityData,
      sprayChartDate,
      pointOfImpactData,
      strikeZoneData,
      verticalAngleData,
    }),
  );
};

export const setReport = report => dispatch => {
  axios.post(
    'https://3fnu3xqbmb.execute-api.us-east-2.amazonaws.com/prod/session',
    report,
  );
  return dispatch({
    type: SET_REPORT,
    payload: report,
  });
};

export const getReports = () => dispatch =>
  axios
    .get('https://3fnu3xqbmb.execute-api.us-east-2.amazonaws.com/prod/session')
    .then(({ data }) =>
      dispatch({
        type: SET_REPORT_HISTORY,
        payload: data.Items,
      }),
    );
