import React from 'react';
import style from '../../style';

const Header = ({ playerName }) => (
  <div style={{ display: 'flex' }} className="group">
    <h2 style={{ ...style, fontSize: '28px', margin: 'auto' }}>{playerName}</h2>
  </div>
);

export default Header;
