import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAverageExitVelocity,
  getMaxExitVelocity,
  getStandardDeviationOfExitVelocity,
  getBarrelConsistency,
  get90thPercentileEV,
} from '../../../../../reducer/session';
import style from '../../../../style';

const ExitVelocity = () => {
  const avgExitVelocity = useSelector(getAverageExitVelocity);
  const maxExitVelocity = useSelector(getMaxExitVelocity);
  const barrelConsistency = useSelector(getBarrelConsistency);
  const standardDeviation = useSelector(getStandardDeviationOfExitVelocity);
  const percentileEv = useSelector(get90thPercentileEV);

  return (
    <div>
      <div style={{ paddingBottom: '20px', paddingTop: '5px' }}>
        <p style={{ ...style, fontSize: '20px' }}>Exit Velocity</p>
        <p style={{ ...style, minWidth: '230px' }}>
          Avg Exit Velocity: {avgExitVelocity.toFixed(2)}
        </p>
        <p style={{ ...style }}>Max Exit Velocity: {maxExitVelocity}</p>
        <p style={{ ...style }}>Top 1/8 EV: {percentileEv}</p>
        <p style={{ ...style }}>Barrel Consistency: {barrelConsistency}%</p>
        <p style={{ ...style }}>
          Std Deviation: {standardDeviation.toFixed(1)}
        </p>
      </div>
    </div>
  );
};

export default ExitVelocity;
