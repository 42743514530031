import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAverageHardHitBallLaunchAngle,
  getAverageLaunchAngle,
  getPercentLineDrives,
} from '../../../../../reducer/session';
import style from '../../../../style';
import { getStandardDeviationOfLaunchAngle } from '../../../../../reducer/session/utils/standardDeviation';

const styles = base => ({
  container: { paddingBottom: '20px' },
  title: { ...base, fontSize: '20px' },
  base,
});

const LaunchAngleTopLine = () => {
  const avgLaunchAngle = useSelector(getAverageLaunchAngle);
  const avgHhbLaunchAngle = useSelector(getAverageHardHitBallLaunchAngle);
  const lineDrivePercentage = useSelector(getPercentLineDrives);
  const laStdDeviation = useSelector(getStandardDeviationOfLaunchAngle);

  const { container, title, base } = styles(style);

  return (
    <div>
      <div style={container}>
        <p style={title}>Launch Angle</p>
        <p style={base}>Avg LA: {avgLaunchAngle.toFixed(1)}</p>
        <p style={base}>Avg HHB LA: {avgHhbLaunchAngle.toFixed(1)}</p>
        <p style={base}>
          {`10\u00B0-30\u00B0: ${(lineDrivePercentage * 100).toFixed(1)}%`}{' '}
        </p>
        <p style={base}>Std Deviation: {laStdDeviation.toFixed(1)}</p>
      </div>
    </div>
  );
};

export default LaunchAngleTopLine;
