import React from 'react';
import { useSelector } from 'react-redux';
import {
  get90thPercentileEV,
  getAverageExitVelocity,
  getAverageHardHitBallLaunchAngle,
  getAverageLaunchAngle,
  getBarrelConsistency,
  getContactRate,
  getLaunchAngleData,
  getMaxExitVelocity,
  getOnbasePlusSlugging,
  getPercentLineDrives,
  getPitchVelocityData,
  getPlayerName,
  getPOIData,
  getSluggingPercentage,
  getSprayChartData,
  getStandardDeviationOfExitVelocity,
  getStrikeZoneData,
  getTotalBallsInPlay,
  getTotalPitches,
  getVerticalAngleData,
} from '../../../reducer/session';
import { getStandardDeviationOfLaunchAngle } from '../../../reducer/session/utils/standardDeviation';
import { getCellColorForZone } from '../components/TopLineAndTables/components/StrikeZoneData';

const ReportPDF = () => {
  const playerName = useSelector(getPlayerName);
  const ballsInPlay = useSelector(getTotalBallsInPlay);
  const totalPitches = useSelector(getTotalPitches);
  const sprayChartData = useSelector(getSprayChartData);
  const verticalAngleData = useSelector(getVerticalAngleData);
  const launchAngleData = useSelector(getLaunchAngleData);
  const avgExitVelocity = useSelector(getAverageExitVelocity);
  const maxExitVelocity = useSelector(getMaxExitVelocity);
  const evStdDeviation = useSelector(getStandardDeviationOfExitVelocity);
  const contactRate = useSelector(getContactRate);
  const sluggingPercentage = useSelector(getSluggingPercentage);
  const ops = useSelector(getOnbasePlusSlugging);
  const strikeZoneData = useSelector(getStrikeZoneData);
  const poiData = useSelector(getPOIData);
  const avgLaunchAngle = useSelector(getAverageLaunchAngle);
  const lineDrivePercentage = useSelector(getPercentLineDrives);
  const avgHhbLaunchAngle = useSelector(getAverageHardHitBallLaunchAngle);
  const laStdDeviation = useSelector(getStandardDeviationOfLaunchAngle);
  const barrelConsistency = useSelector(getBarrelConsistency);
  const pitchVeloData = useSelector(getPitchVelocityData);
  const percentileEv = useSelector(get90thPercentileEV);

  const style = {
    color: '#111111',
    padding: '3px',
    fontSize: '14px',
    margin: '0px',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingTop: '30px',
        paddingBottom: '30px',
        width: '700px',
      }}
      id="reportPdfId"
    >
      <h2 style={{ ...style, fontSize: '16px' }}>{playerName}</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          <div style={{ ...style }}>
            <div style={{ ...style, margin: '0px auto 0px auto' }}>
              Pitch Velocity
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ ...style, minWidth: '30px' }}>Range</p>
              <p style={{ ...style }}>avgEv</p>
              <p style={{ ...style }}>avgLa</p>
            </div>
            {pitchVeloData.map(
              ({ range: { lowerLimit, upperLimit }, avgEv, avgLa }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '5px',
                    marginHorizontal: '8px',
                  }}
                >
                  <p
                    style={{
                      ...style,
                      minWidth: '30px',
                      marginTop: '5px',
                    }}
                  >
                    {/* eslint-disable prettier/prettier */}
                                  {   lowerLimit === 60  ? `< ${upperLimit}`
                                      : upperLimit !== 120 ? `${lowerLimit}-${upperLimit}`
                                          :                      `> ${lowerLimit}`}
                                  {/* eslint-enable prettier/prettier */}
                  </p>
                  <p
                    style={{
                      ...style,
                      marginTop: '5px',
                      marginLeft: '20px',
                    }}
                  >
                    {avgEv ? avgEv.toFixed(1) : '-'}
                  </p>
                  <p
                    style={{
                      ...style,
                      marginTop: '5px',
                      marginLeft: '20px',
                    }}
                  >
                    {avgLa ? avgLa.toFixed(1) : '-'}
                  </p>
                </div>
              ),
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            marginTop: '30px',
            marginLeft: '20px',
          }}
        >
          <div style={{ paddingBottom: '10px' }}>
            <div
              style={{
                ...style,
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
              }}
            >
              <h3
                style={{ ...style, margin: 'auto' }}
              >{`Balls in play: ${ballsInPlay}`}</h3>
              <h3
                style={{ ...style, margin: 'auto' }}
              >{`Total Strikes: ${totalPitches}`}</h3>
            </div>
            <p style={{ ...style, marginTop: '20px', fontSize: '14px' }}>
              Exit Velocity
            </p>
            <p style={{ ...style }}>
              Avg Exit Velocity: {avgExitVelocity.toFixed(2)}
            </p>
            <p style={{ ...style }}>Max Exit Velocity: {maxExitVelocity}</p>
            <p style={{ ...style }}>Top 1/8 EV: {percentileEv}</p>
            <p style={{ ...style }}>Barrel Consistency: {barrelConsistency}%</p>
            <p style={{ ...style }}>
              Std Deviation: {evStdDeviation.toFixed(1)}
            </p>
            <p style={{ ...style, marginTop: '20px' }}>
              Contact Rate: {(contactRate * 100).toFixed(0)}%
            </p>
            <p style={{ ...style }}>
              Slugging: {sluggingPercentage.toFixed(3)}
            </p>
            <p style={{ ...style }}>Projected OPS: {ops.toFixed(3)}</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px',
            alignContent: 'center',
          }}
        >
          <div>
            <p style={{ ...style, fontSize: '14px' }}>Launch Angle</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ ...style, flex: '2', marginRight: '4px' }}>Range</p>
            <p style={{ ...style, flex: '1', marginRight: '4px' }}>maxEv</p>
            <p style={{ ...style, flex: '1', marginRight: '4px' }}>avgEv</p>
            <p style={{ ...style, flex: '1' }}>%</p>
          </div>
          {launchAngleData.map(range => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p style={{ ...style, flex: '2', marginRight: '4px' }}>
                {range.range}
              </p>
              <p style={{ ...style, flex: '1', marginRight: '4px' }}>
                {range.maxEv}
              </p>
              <p style={{ ...style, flex: '1', marginRight: '4px' }}>
                {range.avgEv ? parseFloat(range.avgEv).toFixed(1) : '-'}
              </p>
              <p style={{ ...style, flex: '1' }}>
                {range.percentOfResults
                  ? `${parseFloat(range.percentOfResults).toFixed(1)}%`
                  : '-'}
              </p>
            </div>
          ))}
          <p style={{ ...style, marginTop: '20px' }}>
            Avg LA: {avgLaunchAngle.toFixed(1)}
          </p>
          <p style={{ ...style }}>Avg HHB LA: {avgHhbLaunchAngle.toFixed(1)}</p>
          <p style={{ ...style }}>
            {`10\u00B0-30\u00B0: ${(lineDrivePercentage * 100).toFixed(1)}%`}
          </p>
          <p style={{ ...style }}>Std Deviation: {laStdDeviation.toFixed(1)}</p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '20px',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginBottom: '-60px',
            flexDirection: 'column',
            width: '25%',
          }}
        >
          <p style={{ ...style, fontSize: '14px' }}>Spray Chart</p>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
              }}
            >
              <p style={style}>L</p>
              <p style={style}>
                {parseFloat(sprayChartData.left.percent).toFixed(1)}%
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.left.avgEv).toFixed(1)}
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.left.avgLa).toFixed(1)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
              }}
            >
              <p style={style}>C</p>
              <p style={style}>
                {parseFloat(sprayChartData.center.percent).toFixed(1)}%
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.center.avgEv).toFixed(1)}
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.center.avgLa).toFixed(1)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
              }}
            >
              <p style={style}>R</p>
              <p style={style}>
                {parseFloat(sprayChartData.right.percent).toFixed(1)}%
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.right.avgEv).toFixed(1)}
              </p>
              <p style={style}>
                {parseFloat(sprayChartData.right.avgLa).toFixed(1)}
              </p>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <p style={{ ...style }}>GB: {verticalAngleData.GB.toFixed(1)}%</p>
            <p style={{ ...style }}>FB: {verticalAngleData.FB.toFixed(1)}%</p>
            <p style={{ ...style }}>LD: {verticalAngleData.LD.toFixed(1)}%</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              ...style,
              marginBottom: '10px',
              fontSize: '14px',
            }}
          >
            Strike Zone
          </p>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridTemplateColumns: '50px 50px 50px',
            }}
            className="strike-zone-grid-container-pdf"
          >
            {strikeZoneData.map(zoneData => (
              <div
                key={zoneData.position}
                className={`strike-zone-grid-item-pdf ${getCellColorForZone(
                  avgExitVelocity,
                  zoneData.avgEv,
                )}`}
              >
                <p style={{ margin: '8px' }}>
                  {zoneData.avgEv ? zoneData.avgEv.toFixed(0) : '-'}
                </p>
                <p style={{ margin: '8px', direction: 'ltr' }}>
                  {zoneData.avgLa ? zoneData.avgLa.toFixed(0) : '-'}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '25%',
            marginRight: '30px',
          }}
        >
          <p style={{ ...style, fontSize: '14px' }}>POI Chart</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '14px',
                textAlign: 'right',
              }}
            >
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    ...style,
                    padding: '0px 16px',
                    margin: '0px',
                    width: '50px',
                    textAlign: 'right',
                  }}
                >
                  Range
                </p>
                <div style={{ display: 'flex', padding: '0px 16px' }}>
                  <div>
                    <p
                      style={{
                        ...style,
                        width: '50px',
                        padding: '0px',
                      }}
                    >
                      Avg EV
                    </p>
                  </div>
                  <div>
                    <p style={{ ...style, width: '25px', padding: '0px 15px' }}>
                      %
                    </p>
                  </div>
                </div>
              </div>
              {poiData
                .sort((poi1, poi2) =>
                  poi1.range.lowerLimit < poi2.range.lowerLimit ? 1 : -1,
                )
                .map(poiRange => (
                  <div style={{ display: 'flex' }}>
                    <p
                      style={{
                        ...style,
                        padding: '5px 16px',
                        margin: '0px',
                        width: '50px',
                        textAlign: 'right',
                      }}
                    >
                      {poiRange.range.upperLimit === 30
                        ? '>18'
                        : poiRange.range.lowerLimit === -18
                        ? '<-18'
                        : poiRange.range.lowerLimit}
                    </p>
                    <div style={{ display: 'flex', padding: '0px 16px' }}>
                      <div>
                        <p
                          style={{
                            ...style,
                            width: '25px',
                            padding: '5px 10px',
                          }}
                        >
                          {poiRange.avgEv ? poiRange.avgEv.toFixed(1) : '-'}
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            ...style,
                            width: '25px',
                            padding: '5px 25px',
                            textAlign: 'right',
                          }}
                        >
                          {poiRange.percentBallsInPlay
                            ? `${poiRange.percentBallsInPlay.toFixed(0)}%`
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPDF;
