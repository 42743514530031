export const SET_SESSION_AT_BATS = 'SET_SESSION_AT_BATS';
export const SET_PLAYER_NAME = 'SET_PLAYER_NAME';
export const GET_NAME_FOR_SESSION = 'GET_NAME_FOR_SESSION';
export const CANCEL_PLAYER_NAME = 'CANCEL_PLAYER_NAME';
export const ADD_SESSION_AT_BATS = 'ADD_SESSION_AT_BATS';
export const CLEAR_AT_BATS = 'CLEAR_AT_BATS';

export const setSessionAtBats = atBats => ({
  type: SET_SESSION_AT_BATS,
  payload: atBats,
});

export const addSessionAtBats = atBats => ({
  type: ADD_SESSION_AT_BATS,
  payload: atBats,
});

export const clearAtBats = () => ({
  type: CLEAR_AT_BATS,
});

export const setPlayerName = playerName => ({
  type: SET_PLAYER_NAME,
  payload: playerName,
});

export const getNameForSession = data => ({
  type: GET_NAME_FOR_SESSION,
  payload: data,
});

export const cancelPlayerName = () => ({
  type: CANCEL_PLAYER_NAME,
});
