/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  ADD_SESSION_AT_BATS,
  CANCEL_PLAYER_NAME,
  CLEAR_AT_BATS,
  GET_NAME_FOR_SESSION,
  SET_PLAYER_NAME,
  SET_SESSION_AT_BATS,
} from './actions';
// eslint-disable-next-line import/no-cycle
import { convertSessionStringToAtBats } from '../../components/select/ImportFromFileBody';
import {
  getAtBatsOver50ExitVelocityAndPitchVelocity,
  getAtBatsOver50PitchVelocity,
  getBallsInPlay,
} from './utils';
import { getStandardDeviationOfNumberSet } from './utils/standardDeviation';
import {
  getAtBatsInHorizontalAngleRange,
  getAtBatsInLaunchVeloRange,
  getAtBatsInPitchVeloRange,
  getAtBatsInPOIRange,
  getHorizontalAngleRanges,
  getLaunchAngleRanges,
  getPitchVelocityRanges,
  getPOIRanges,
} from './utils/range';

const initialState = {
  atBats: [],
  playerName: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_AT_BATS: {
      const atBats = mapRawAtBatsToAtBats(action.payload);
      const { playerName } = atBats[1];
      return { ...state, atBats, playerName };
    }
    case ADD_SESSION_AT_BATS: {
      const atBats = mapRawAtBatsToAtBats(action.payload);
      const { playerName } = atBats[1];
      return { ...state, atBats: [...state.atBats, ...atBats], playerName };
    }
    case CLEAR_AT_BATS:
      return { ...state, atBats: [], playerName: '' };
    case GET_NAME_FOR_SESSION: {
      const atBats = mapRawAtBatsToAtBats(
        convertSessionStringToAtBats(action.payload),
      );
      return {
        ...state,
        atBats: [...state.atBats, ...atBats],
        playerName: '',
        isAwaitingName: true,
      };
    }
    case SET_PLAYER_NAME: {
      return { ...state, playerName: action.payload, isAwaitingName: false };
    }
    case CANCEL_PLAYER_NAME: {
      return { ...state, atBats: [], isAwaitingName: false };
    }
    default: {
      return state;
    }
  }
};

export const mapRawAtBatsToAtBats = rawAtBats =>
  rawAtBats
    .filter(rawAtBat => _.isObject(rawAtBat))
    .map(rawAtBat => ({
      pitchVelocity: _.divide(parseFloat(rawAtBat[' Pitch']), 0.865),
      exitVelocity: parseFloat(rawAtBat[' Velo']),
      launchAngle: parseFloat(rawAtBat[' LA']),
      horizontalAngle: parseFloat(rawAtBat[' Horiz. Angle']),
      strikeZonePosition: parseInt(rawAtBat[' Strike Zone'], 10),
      result: rawAtBat[' Res'],
      playerName: _.has(rawAtBat, ' User') ? rawAtBat[' User'] : '',
      resultType: rawAtBat[' Type'],
      poiX: rawAtBat[' POI X'],
      poiY: rawAtBat[' POI Y'],
      poiZ: rawAtBat[' POI Z'],
    }));

export const getPlayerName = state =>
  state.session.atBats.length > 0 && state.session.playerName;

export const getTotalPitches = state =>
  getAtBatsOver50PitchVelocity(state.session.atBats).filter(
    atBat =>
      (atBat.strikeZonePosition &&
        _.inRange(atBat.strikeZonePosition, 1, 10)) ||
      _.inRange(atBat.horizontalAngle, -45.0, 45.0),
  ).length;

export const getTotalBallsInPlay = state =>
  getAtBatsOver50ExitVelocityAndPitchVelocity(
    state.session.atBats,
  ).filter(atBat => _.inRange(atBat.horizontalAngle, -45.0, 45.0)).length;

export const getAverageExitVelocity = state =>
  _(getAtBatsOver50ExitVelocityAndPitchVelocity(state.session.atBats))
    .map(atBat => atBat.exitVelocity)
    .mean();

export const getMaxExitVelocity = state =>
  _(getAtBatsOver50ExitVelocityAndPitchVelocity(state.session.atBats))
    .map(atBat => atBat.exitVelocity)
    .max();

export const getBarrelConsistency = state =>
  ((getAverageExitVelocity(state) / getMaxExitVelocity(state)) * 100).toFixed(
    1,
  );

export const getStandardDeviationOfExitVelocity = state => {
  const applicableAtBats = getAtBatsOver50ExitVelocityAndPitchVelocity(
    state.session.atBats,
  );
  const setOfExitVelocityFromAtBats = applicableAtBats.map(
    atBat => atBat.exitVelocity,
  );

  return getStandardDeviationOfNumberSet(setOfExitVelocityFromAtBats);
};

export const getAverageLaunchAngle = state =>
  _(getAtBatsOver50ExitVelocityAndPitchVelocity(state.session.atBats))
    .map(atBat => atBat.launchAngle)
    .mean();

export const getAverageHardHitBallLaunchAngle = state =>
  _(getAtBatsOver50ExitVelocityAndPitchVelocity(state.session.atBats))
    .filter(atBat => atBat.exitVelocity > 80.0)
    .map(atBat => atBat.launchAngle)
    .mean();

export const getPercentLineDrives = state => {
  const ballsInPlay = getBallsInPlay(state);
  const lineDriveCount = ballsInPlay.filter(atBat =>
    _.inRange(atBat.launchAngle, 10, 30),
  ).length;
  return lineDriveCount / ballsInPlay.length;
};

export const getSluggingPercentage = state => {
  const totalPoints = getAtBatsOver50ExitVelocityAndPitchVelocity(
    state.session.atBats,
  )
    .filter(atBat => _.inRange(atBat.horizontalAngle, -45.0, 45.0))
    .map(getAtBatResultPointValue)
    .reduce((acc, curr) => acc + curr, 0);
  return parseFloat(totalPoints) / parseFloat(getTotalBallsInPlay(state));
};

export const getOnbasePlusSlugging = state =>
  1.374 * getSluggingPercentage(state) + 0.411 * getContactRate(state);

export const getContactRate = state =>
  parseFloat(getTotalBallsInPlay(state)) / parseFloat(getTotalPitches(state));

const getAtBatResultPointValue = ({ result }) => {
  if (!result || (result.length < 3 && result.trim().toLowerCase() !== 'hr'))
    return 0;

  switch (
    result
      .substring(0, 3)
      .trim()
      .toLowerCase()
  ) {
    case '1b':
      return 1;
    case '2b':
      return 2;
    case '3b':
      return 3;
    case 'hr':
      return 4;
    default:
      return 0;
  }
};

export const getLaunchAngleData = state => {
  const ranges = getLaunchAngleRanges();
  const ballsInPlay = getBallsInPlay(state);
  return ranges.map(range => {
    const atBatsInRange = getAtBatsInLaunchVeloRange(ballsInPlay, range);
    return {
      range: getHeaderTextForLAZone(range),
      maxEv: _(atBatsInRange)
        .map(atBat => atBat.exitVelocity)
        .max(),
      avgEv: _(atBatsInRange)
        .map(atBat => atBat.exitVelocity)
        .mean(),
      percentOfResults:
        (parseFloat(atBatsInRange.length) / parseFloat(ballsInPlay.length)) *
        100,
    };
  });
};

export const getPitchVelocityData = state => {
  const ranges = getPitchVelocityRanges();
  const ballsInPlay = getBallsInPlay(state);
  return ranges.map(range => {
    const atBatsInRange = getAtBatsInPitchVeloRange(ballsInPlay, range);
    return {
      range,
      avgLa: _(atBatsInRange)
        .map(atBat => atBat.launchAngle)
        .mean(),
      avgEv: _(atBatsInRange)
        .map(atBat => atBat.exitVelocity)
        .mean(),
      percentOfResults:
        (parseFloat(atBatsInRange.length) / parseFloat(ballsInPlay.length)) *
        100,
    };
  });
};

export const getSprayChartData = state => {
  const ranges = getHorizontalAngleRanges();
  const ballsInPlay = getBallsInPlay(state);
  return ranges.reduce((result, range, idx) => {
    const atBatsInRange = getAtBatsInHorizontalAngleRange(ballsInPlay, range);
    return {
      ...(idx !== 0 && result),
      [`${
        range.upperLimit === -15
          ? 'left'
          : range.upperLimit === 15
          ? 'center'
          : 'right'
      }`]: {
        avgLa: _(atBatsInRange)
          .map(atBat => atBat.launchAngle)
          .mean(),
        avgEv: _(atBatsInRange)
          .map(atBat => atBat.exitVelocity)
          .mean(),
        percent:
          (parseFloat(atBatsInRange.length) / parseFloat(ballsInPlay.length)) *
          100,
      },
    };
  }, {});
};

export const getPOIData = state => {
  const ranges = getPOIRanges();
  const ballsInPlay = getBallsInPlay(state);
  return ranges.map(range => {
    const atBatsInRange = getAtBatsInPOIRange(ballsInPlay, range);
    return {
      range,
      avgEv: _(atBatsInRange)
        .map(atBat => atBat.exitVelocity)
        .mean(),
      percentBallsInPlay:
        (parseFloat(atBatsInRange.length) / parseFloat(ballsInPlay.length)) *
        100,
    };
  });
};

export const getStrikeZoneData = state => {
  const positions = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const ballsInPlay = getBallsInPlay(state);
  return positions.map(position => {
    const atBatsWithPitchPosition = ballsInPlay.filter(
      atBat => atBat.strikeZonePosition === position,
    );
    if (atBatsWithPitchPosition.length < 1) {
      return { position, avgEv: 0, avgLa: 0 };
    }
    return {
      position,
      avgEv: _(atBatsWithPitchPosition)
        .map(atBat => atBat.exitVelocity)
        .mean(),
      avgLa: _(atBatsWithPitchPosition)
        .map(atBat => atBat.launchAngle)
        .mean(),
    };
  });
};

export const getVerticalAngleData = state => {
  const resultTypes = ['GB', 'LD', 'FB'];
  const ballsInPlay = getBallsInPlay(state);
  return resultTypes.reduce((acc, resultType, idx) => {
    const atBatsWithResultType = ballsInPlay.filter(
      atBat => atBat.resultType.trim() === resultType,
    );
    return {
      ...(idx !== 0 && acc),
      [resultType]: (atBatsWithResultType.length / ballsInPlay.length) * 100,
    };
  }, {});
};

export const get90thPercentileEV = state => {
  const orderedExitVelocity = _(getBallsInPlay(state))
    .orderBy('exitVelocity', 'asc')
    .map(atBat => atBat.exitVelocity)
    .value();

  const rootIndex = (90 / 100) * orderedExitVelocity.length;
  const lower = orderedExitVelocity[Math.floor(rootIndex) - 1];
  const upper = orderedExitVelocity[Math.ceil(rootIndex) - 1];
  return ((lower + upper) / 2).toFixed(1);
};

const getHeaderTextForLAZone = ({ upperLimit, lowerLimit }) =>
  upperLimit === -10
    ? '< -10'
    : lowerLimit === 40
    ? '> 40'
    : `${lowerLimit.toString()} - ${upperLimit.toString()}`;

export default reducer;
