import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import {
  getTotalBallsInPlay,
  getOnbasePlusSlugging,
  getSluggingPercentage,
  getTotalPitches,
} from '../../../../../reducer/session';
import style from '../../../../style';

const ContactRateSluggingAndOPS = () => {
  const ballsInPlay = useSelector(getTotalBallsInPlay);
  const totalPitches = useSelector(getTotalPitches);
  const sluggingPercentage = useSelector(getSluggingPercentage);
  const ops = useSelector(getOnbasePlusSlugging);

  return (
    <>
      <Tooltip
        theme="transparent"
        html={
          <>
            <p>Balls in Play</p>
            <p>{ballsInPlay}</p>
            <p>Total Strikes</p>
            <p>{totalPitches}</p>
          </>
        }
        position="left"
      >
        <p style={style}>
          Contact Rate:{' '}
          {((parseFloat(ballsInPlay) / parseFloat(totalPitches)) * 100).toFixed(
            0,
          )}
          %
        </p>
      </Tooltip>
      <p style={style}>Slugging: {sluggingPercentage.toFixed(3)}</p>
      <p style={style}>Projected OPS: {ops.toFixed(3)}</p>
    </>
  );
};

export default ContactRateSluggingAndOPS;
