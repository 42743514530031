import React, { useState } from 'react';
import { Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { cancelPlayerName, setPlayerName } from '../../reducer/session/actions';
import { generateReport } from '../../reducer/report/actions';

const NameSelectModal = ({ show }) => {
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const handleChange = e => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(setPlayerName(name));
    dispatch(generateReport());
  };

  const cancelModal = () => dispatch(cancelPlayerName());

  return (
    <Modal
      visible={show}
      title="Enter Player Name"
      onOk={handleSubmit}
      onCancel={cancelModal}
      okText="Submit"
      centered
    >
      <div style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            padding: '20px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'auto',
          }}
        >
          <Input
            type="text"
            placeholder="Player name"
            onChange={handleChange}
            value={name}
            onPressEnter={handleSubmit}
            autoFocus
          />
        </div>
      </div>
    </Modal>
  );
};

export default NameSelectModal;
