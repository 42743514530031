import React from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../style';
import { getVerticalAngleData } from '../../../../../reducer/session';

export default () => {
  const { GB, LD, FB } = useSelector(getVerticalAngleData);
  return (
    <div>
      <p style={{ ...style }}>GB: {GB.toFixed(1)}%</p>
      <p style={{ ...style }}>FB: {FB.toFixed(1)}%</p>
      <p style={{ ...style }}>LD: {LD.toFixed(1)}%</p>
    </div>
  );
};
