import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {Button} from 'antd';
import {useDropzone} from 'react-dropzone';
import {
  addSessionAtBats,
  clearAtBats,
  getNameForSession,
} from '../../reducer/session/actions';

export const convertSessionStringToAtBats = sessionAsString => {
  const arrayedAtBats = sessionAsString.split('\n');
  const headers = arrayedAtBats[0].split(',');
  return arrayedAtBats.map((atBat, idx) =>
    idx === 0
      ? null
      : atBat.split(',').reduce((acc, val, idx2) => ({
          ...acc,
          [`${headers[idx2]}`]: val,
        })),
  );
};

const headers =
  ' AB,Date, Time Stamp, Pitch, Strike Zone, P. Type, Velo, LA, Dist, Res, Type, Horiz. Angle, Pts, Hand Speed, BV, Trigger to Impact, AA, Impact Momentum, Strike Zone Bottom, Strike Zone Top, Strike Zone Width, Vertical Distance, Horizontal Distance, POI X, POI Y, POI Z, Bat Material, User, Pitch Angle, Batting, Level, Opposing Player, Tag,\n';

const ImportFromFileBody = () => {
  const dispatch = useDispatch();

  const getAtBatsFromCsv = fileReader => {
    return () => {
      if (fileReader.result.includes('User')) {
        dispatch(
          addSessionAtBats(convertSessionStringToAtBats(fileReader.result)),
        );
      } else {
        dispatch(getNameForSession(headers + fileReader.result));
      }
    };
  };

  const handleFileChosen = files => {
    dispatch(clearAtBats());
    files.forEach(file => {
      const fileReader = new FileReader();
      fileReader.onload = getAtBatsFromCsv(fileReader);
      fileReader.readAsText(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChosen,
  });

  return (
    <div className="import-file-button submit-buttons">
      <div className="button" {...getRootProps()}>
        <input {...getInputProps()} />
        <Button type="primary" shape="round" icon="upload" size="large">
          Upload
        </Button>
      </div>
    </div>
  );
};

export default connect()(ImportFromFileBody);
