import * as _ from 'lodash';
import { getAtBatsOver50ExitVelocityAndPitchVelocity } from './index';

export const getStandardDeviationOfNumberSet = numberSet => {
  const avg = _.sum(numberSet) / numberSet.length;

  return Math.sqrt(
    _.sum(_.map(numberSet, i => (i - avg) ** 2)) / numberSet.length,
  );
};
export const getStandardDeviationOfLaunchAngle = state => {
  const applicableAtBats = getAtBatsOver50ExitVelocityAndPitchVelocity(
    state.session.atBats,
  );
  const setOfLaunchAngleFromAtBats = applicableAtBats.map(
    atBat => atBat.launchAngle,
  );
  return getStandardDeviationOfNumberSet(setOfLaunchAngleFromAtBats);
};
