import React from 'react';
import SprayChartData from './components/SprayChartData';
import PoiData from './components/PoiData';
import style from '../../../style';
import VerticalAngleData from './components/VerticalAngleData';

const Charts = () => (
  <div
    style={{ display: 'flex', justifyContent: 'space-between' }}
    className="group"
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
      }}
      className="spray-chart-column column"
    >
      <SprayChartData />
      <VerticalAngleData />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20px',
      }}
      className="poi-column column"
    >
      <p style={{ ...style, fontSize: '20px' }}>POI Chart</p>
      <PoiData />
    </div>
  </div>
);

export default Charts;
