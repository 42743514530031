import React, { useEffect, useState } from 'react';
import domtoimage from 'dom-to-image';
import '../index.css';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import ReportPDF from './index';
import { getPlayerName } from '../../../reducer/session';

const ExportPdf = () => {
  const playerName = useSelector(getPlayerName);
  const [base64Report, setBase64Report] = useState(null);

  const generateBase64FromReport = () => {
    const report = document.getElementById('reportPdfId');
    domtoimage.toPng(report).then(dataUrl => {
      setBase64Report(dataUrl);
    });
  };

  useEffect(() => {
    generateBase64FromReport();
  }, []);

  const pdfFileName = `${playerName
    .toLowerCase()
    .trim()
    .replace(' ', '-')}.png`;

  return (
    <>
      <Button
        download={pdfFileName}
        href={base64Report}
        type="default"
        shape="round"
        icon="download"
        size="large"
        loading={!base64Report}
      >
        Download PNG
      </Button>
      <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
        <ReportPDF />
      </div>
    </>
  );
};
export default ExportPdf;
