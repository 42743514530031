import React from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../style';
import { getLaunchAngleData } from '../../../../../reducer/session';

const styles = base => ({
  dataCell: { ...base, flex: '1' },
  title: { ...base, fontSize: '20px' },
  dataRow: { display: 'flex', justifyContent: 'space-between' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
  },
});

const LaunchAngleMetrics = () => {
  const { dataCell, title, dataRow, container } = styles(style);
  const launchAngleMetrics = useSelector(getLaunchAngleData);

  return (
    <div>
      <div style={container}>
        <div>
          <p style={title}>Launch Angle</p>
        </div>
        <div style={dataRow}>
          <p style={dataCell}>Range</p>
          <p style={dataCell}>maxEv</p>
          <p style={dataCell}>avgEv</p>
          <p style={dataCell}>%</p>
        </div>
        {launchAngleMetrics.map(({ range, maxEv, avgEv, percentOfResults }) => (
          <div style={dataRow}>
            <p style={dataCell}>{range}</p>
            <p style={dataCell}>{maxEv || '-'}</p>
            <p style={dataCell}>{avgEv ? parseFloat(avgEv).toFixed(1) : '-'}</p>
            <p style={dataCell}>{parseFloat(percentOfResults).toFixed(1)}%</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LaunchAngleMetrics;
