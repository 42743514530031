import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { SET_REPORT_HISTORY, SET_REPORT } from './actions';

const current = (state = {}, action) =>
  action.type === SET_REPORT ? action.payload : state;

const historyById = (state = {}, action) =>
  action.type === SET_REPORT_HISTORY ? _.keyBy(action.payload, 'id') : state;

const historyIdsByPlayerName = (state = {}, action) =>
  action.type === SET_REPORT_HISTORY
    ? action.payload.reduce(
        (acc, elem) => ({
          ...(acc || {}),
          [`${elem.playerName}`]: [
            ...(acc[`${elem.playerName}`] || []),
            elem.id,
          ],
        }),
        {},
      )
    : state;

const reducer = combineReducers({
  current,
  historyById,
  historyIdsByPlayerName,
});

export default reducer;
