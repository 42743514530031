import React from 'react';
import { useSelector } from 'react-redux';
import HomePlate from '../../../../../assets/home-plate.svg';
import '../../../index.css';
import style from '../../../../style';
import { getPOIData } from '../../../../../reducer/session';

const Column = ({ header, data }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div>
      <p style={style}>{header}</p>
    </div>
    {data.map(item => (
      <div style={{ margin: '0 auto' }}>
        <p style={style}>{item}</p>
      </div>
    ))}
  </div>
);

const PoiData = () => {
  const data = useSelector(getPOIData);
  data.sort((poi1, poi2) =>
    poi1.range.lowerLimit < poi2.range.lowerLimit ? 1 : -1,
  );
  const ranges = data.map(({ range }) =>
    range.upperLimit === 30
      ? '>18'
      : range.lowerLimit === -18
      ? '<-18'
      : range.lowerLimit,
  );
  const avgEvs = data.map(({ avgEv }) => (!avgEv ? '-' : avgEv.toFixed(1)));
  const percentBallsInPlays = data.map(({ percentBallsInPlay }) =>
    percentBallsInPlay.toFixed(0) === '0'
      ? '-'
      : `${percentBallsInPlay.toFixed(0)}%`,
  );
  return (
    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '14px' }}>
      <div>
        <img
          src={HomePlate}
          width="220px"
          alt="home plate"
          style={{ paddingTop: '140px' }}
          className="home-plate"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '14px',
          textAlign: 'right',
          margin: '0 auto',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Column header="Range" data={ranges} />
          <Column header="Avg EV" data={avgEvs} />
          <Column header="%" data={percentBallsInPlays} />
        </div>
      </div>
    </div>
  );
};

export default PoiData;
